import React, { ReactNode } from 'react';
import Restricted, { usePermissions } from '../../restricted/restricted';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Divider } from '@mui/material';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import List from '@mui/material/List/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemButton from '@mui/material/ListItemButton/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import MapIcon from '@mui/icons-material/Map';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { UserPermissions } from '../../restricted/interfaces';
import { useEnhed, useEnhedId } from '../../../shared/hooks/use-enhed';
import useHistoryNavigate from '../../../shared/hooks/extended-hooks/use-history-navigate';
import GridOnIcon from '@mui/icons-material/GridOn';
import BoltIcon from '@mui/icons-material/Bolt';
import BarChartIcon from '@mui/icons-material/BarChart';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ListItemCollapse from '../../mui/mui-list-item-collapse';
import DescriptionIcon from '@mui/icons-material/Description';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import { NavLink } from 'react-router-dom';
interface IBurgerMenuListProps {
    close?: VoidFunction;
}

interface IBurgerMenuListItemProps {
    path?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    tekst: string;
    close?: VoidFunction;
    icon?: ReactNode;
    disabled?: boolean;
}

/**
 *
 * @param path hvor skal menu-item'et lede hen
 * @param tekst tekst som vises ved siden af ikonet
 * @param icon mui-icon
 * @param close funktion til at skjule menuen, når der klikkes på et element
 * @param disabled til når restrictions skal kunne disable et element
 *
 * @returns
 */
export const BurgerMenuListItem = ({
    path,
    onClick: _handleClick,
    tekst,
    close,
    icon,
    disabled = false,
}: IBurgerMenuListItemProps) => {
    const { navigate } = useHistoryNavigate();
    const id = useEnhedId();

    const handleClick: React.MouseEventHandler<HTMLAnchorElement> =
        _handleClick ??
        (() => {
            close?.();
        });

    if (_handleClick) {
        return (
            <ListItem key={tekst} disablePadding sx={{ paddingLeft: '4px' }}>
                <ListItemButton onClick={(e: any) => _handleClick(e)} disabled={disabled}>
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={tekst} />
                </ListItemButton>
            </ListItem>
        );
    }
    return (
        <NavLink
            to={path ? `/${id}${path}` : window.location.pathname + window.location.search}
            onClick={(e) => {
                handleClick(e);
            }}
            style={({ isActive, isPending, isTransitioning }) => {
                return {
                    backgroundColor: isActive && path !== '/' ? '#cccccc' : 'white',
                    viewTransitionName: isTransitioning ? 'slide' : '',
                };
            }}
        >
            <ListItem key={tekst} disablePadding sx={{ paddingLeft: '4px', background: 'inherit' }}>
                <ListItemButton disabled={disabled}>
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={tekst} />
                </ListItemButton>
            </ListItem>
        </NavLink>
    );
};

/**
 *
 * @param close funktion til at skjule menuen når der klikkes på et element
 * @returns Liste af elementer til draweren
 */
export const BurgerMenuList = ({ close }: IBurgerMenuListProps) => {
    const { showList } = useEnhed();
    const { hasPermissions } = usePermissions();

    return (
        <List>
            <BurgerMenuListItem
                tekst={hasPermissions(UserPermissions.OK) ? 'Vælg anlæg' : 'Vælg enhed'}
                onClick={() => {
                    showList();
                    close?.();
                }}
                icon={<FormatListNumberedIcon />}
            />
            <Divider />
            <Restricted permissions={UserPermissions.Admin}>
                <BurgerMenuListItem tekst='Overblik' path={`/Overview`} close={close} icon={<ViewCarouselIcon />} />
            </Restricted>
            <Divider />
            <Restricted permissions={UserPermissions.Drift}>
                <BurgerMenuListItem tekst='Drift' path={`/Drift`} close={close} icon={<ListAltIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Team}>
                <BurgerMenuListItem tekst='Team' path={`/Team`} close={close} icon={<GroupsIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Drift_Journal}>
                <BurgerMenuListItem tekst='Journal' path={`/Timeline`} close={close} icon={<LinearScaleIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Drift || UserPermissions.Team}>
                <Divider />
            </Restricted>
            <Restricted permissions={UserPermissions.Forsyning}>
                <>
                    <List>
                        <ListItemCollapse primary='Forsyning' icon={<BoltIcon />} divider={false} marginLeft='24px'>
                            <BurgerMenuListItem
                                tekst='Forbrug'
                                path={`/Forbrug`}
                                close={close}
                                icon={<BarChartIcon />}
                            />
                            <BurgerMenuListItem
                                tekst='Stamdata'
                                path={`/Forsyning`}
                                close={close}
                                icon={<DescriptionIcon />}
                            />
                            <Restricted permissions={UserPermissions.Forsyning_Admin}>
                                <BurgerMenuListItem
                                    tekst='Administration'
                                    path={`/ForsyningAdmin`}
                                    close={close}
                                    icon={<ContentPasteSearchIcon />}
                                />
                            </Restricted>
                        </ListItemCollapse>
                    </List>
                </>
            </Restricted>
            <Restricted permissions={UserPermissions.Forsyning}>
                <Divider />
            </Restricted>
            <Restricted permissions={UserPermissions.Data_Extraction}>
                <BurgerMenuListItem tekst='Dataudtræk' path={`/DataExtraction`} close={close} icon={<GridOnIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Rapport}>
                <BurgerMenuListItem tekst='Rapport' path={`/Rapport`} close={close} icon={<PostAddIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Map} unauthorizedProps={{ disabled: true }}>
                <BurgerMenuListItem tekst='Kort' path='/Map' close={close} icon={<MapIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Admin}>
                <BurgerMenuListItem tekst='Admin' path='/Admin' close={close} icon={<AdminPanelSettingsIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Bygningsdelskort}>
                <BurgerMenuListItem
                    tekst='Bygningsdelskort'
                    path={`/Bygningsdelskort`}
                    close={close}
                    icon={<MapsHomeWorkIcon />}
                />
            </Restricted>
            <Restricted permissions={UserPermissions.Bygningsdelskort}>
                <BurgerMenuListItem tekst='Stamdata' path={`/Stamdata`} close={close} icon={<DocumentScannerIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Syn}>
                <BurgerMenuListItem tekst='Syn' path={`/Syn`} close={close} icon={<AssignmentIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Service}>
                <BurgerMenuListItem tekst='Service' path={`/Service`} close={close} icon={<HomeRepairServiceIcon />} />
            </Restricted>
            <Restricted permissions={UserPermissions.Admin}>
                <BurgerMenuListItem tekst='Denied' path='/Denied' close={close} icon={<DoNotDisturbOnIcon />} />
            </Restricted>
        </List>
    );
};

export default BurgerMenuList;
