/* eslint-disable no-restricted-imports */

import version from './config.json';

// Do not change these values, use a .env file instead. An example file has been supplied.

const base = new URL(import.meta.env.VITE_SERVER_URL_BASE ?? 'https://devapi.caretaker.dk');
const noTopDomain = /(.*)\./.exec(base.hostname)?.[1];

export const NAME = noTopDomain;

export const VERSION = version.VERSION;
export const BUILD = import.meta.env.VITE_BUILD_NUMBER ?? 'no number'; //version.BUILD;
export const BUILD_TIME = version.BUILD_TIME;
export const FULL_VERSION = `${VERSION}.${BUILD}`;

export const SERVER_URL_BASE = base.toString();
export const SERVER_URL = base.toString() + 'api';
export const NODE_TEST_API = import.meta.env.VITE_NODE_TEST_API ?? 'https://reactnode.caretaker.dk';
export const DASHBOARD_URL =
    import.meta.env.VITE_DASHBOARD_URL ?? 'https://devapi.caretaker.dk/api/dashboard';

export const AUTH_SERVER_URL_BASE =
    import.meta.env.VITE_AUTH_SERVER_URL_BASE ?? 'https://caretaker-nextauth.azurewebsites.net';

/** Show MSFT sign-in button */
export const UI_SIGNIN_SHOW_MSFT_SIGNIN = import.meta.env.VITE_UI_SIGNIN_SHOW_MSFT_SIGNIN !== 'false';

/** Show Caretaker's custom sign-in button */
export const UI_SIGNIN_SHOW_CT_SIGNIN = import.meta.env.VITE_UI_SIGNIN_SHOW_CT_SIGNIN !== 'false';

const config = {
    NAME,

    VERSION,
    BUILD,
    BUILD_TIME,
    FULL_VERSION,

    SERVER_URL_BASE,
    SERVER_URL,
    NODE_TEST_API,
    DASHBOARD_URL,

    AUTH_SERVER_URL_BASE,
} as const;

export default config;
