import { LoaderFunctionArgs, RouteObject, redirect } from 'react-router-dom';

import OverviewLayout from './overview.layout';
import { lazy } from 'react';

const OverviewGraphsScreen = lazy(() => import('./screens/graphs.screen'));
const OverviewDebugScreen = lazy(() => import('./screens/debug.screen'));

const handleRedirect = ({ request, params }: LoaderFunctionArgs) => {
    // return null if url contains at least 4 segments
    // param "overview" param "graph / some other segment"
    const url = new URL(request.url);
    if (/(\/.+){4}/.test(url.pathname)) return null;

    const { id, overviewContextName = 'Context' } = params;
    return redirect(`/${id}/Overview/${overviewContextName}/Graphs${url.hash}${url.search}`);
};

const OverviewRouteObjects: RouteObject[] = [
    {
        path: '*',
        loader: handleRedirect,
        children: [
            {
                path: ':overviewContextName/*',
                loader: handleRedirect,
                element: <OverviewLayout />,
                children: [
                    {
                        path: 'Graphs',
                        element: <OverviewGraphsScreen />,
                    },
                    {
                        path: 'Debug',
                        element: <OverviewDebugScreen />,
                    },
                ],
            },
        ],
    },
];

export default OverviewRouteObjects;
