import { useEffect } from 'react';
import useFirstRender from './use-first-render';
import useStable from './use-stable';

/** useEffect which doesn't run on initial render */
const useRerenderEffect = (effect: React.EffectCallback, deps?: React.DependencyList | undefined) => {
    const first = useStable(useFirstRender());
    const effectRef = useStable(effect);
    useEffect(() => {
        if (first.current) return;
        return effectRef.current();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export default useRerenderEffect;
