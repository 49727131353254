import Feature, { FeatureLike } from 'ol/Feature';

import CTLayer from './CTLayer';
import { ControlHandler } from './ControlHandler';
import { Coordinate } from 'ol/coordinate';
import { FeatureProperty } from './feature-utility';
import Geometry from 'ol/geom/Geometry';
import { IDictionary } from '../../../shared/utils/types';
import Overlay from 'ol/Overlay';
import OverlayPositioning from 'ol/OverlayPositioning';
import { PluggableMap } from 'ol';
import { SFC } from './react-controls/search-features/control';
import { Tools } from './EditingControl';
import _ from 'lodash';
import getOverlayElement from './react-components/clickable-features-overlays';

//import { UrlParams } from './UrlParams';

//import Map from 'ol/Map';

export enum ClickableFeatureType {
    Pin,
    SqlDel,
    Del,
    SqlLok,
    Lok,
    SqlByg,
    Byg,
    Enh,
    Default,
}

export interface IZoomOptions {
    animate?: boolean;
    maxZoom?: number;
}

//#region ClickableFeatures

export class ClickableFeature<TGeometry extends Geometry = Geometry> extends Feature<TGeometry> {
    public static singleton = new ClickableFeature();
    //public static _map;
    private static _eventlistenerIsAdded = false;
    public dataColumns: string[] = [];
    public webPage: string = 'http://localhost:55787/Byg/BygMenu/{BygTerSYS}?enhedId={ENHEDSYS}&showTopmenu=hide';
    public featureType: ClickableFeatureType = ClickableFeatureType.Default;
    // public webPage : string = null;

    private static map: PluggableMap;

    /** Some functionality is weirdly affected by zooming / panning on click */
    public static suppressClicks = false;

    public static checkType(obj: FeatureLike): boolean {
        const keys2 = Object.keys(obj);
        return Object.keys(this.singleton).every((k1) => keys2.includes(k1));
    }

    public static createGUIPopupElement(map: PluggableMap, feature: ClickableFeature, overlay: Overlay) {
        const fdata = feature.get(FeatureProperty.Data);
        const element = document.createElement('div');
        ['ol-popup', 'ol-buildingLayersPopup'].forEach((c) => element.classList.add(c));
        if (feature.webPage != null) {
            //#region Portal menu
            let wp = feature.webPage;
            let rightT;
            for (let leftT = wp.indexOf('{'); leftT > -1; leftT = wp.indexOf('{')) {
                rightT = wp.indexOf('}', leftT);
                const left = wp.slice(0, leftT);
                const middle = wp.slice(leftT + 1, rightT);
                const right = wp.slice(rightT + 1, wp.length);
                wp = left + fdata[middle] + right;
            }

            const web = document.createElement('iframe');
            web.src = wp;
            web.width = '300';
            web.height = '340';
            web.style.border = 'none';
            web.addEventListener('load', (_) => {
                web.contentDocument?.querySelectorAll('.menuround').forEach((b) =>
                    b.addEventListener('click', (e) => {
                        e.preventDefault();
                        // There must be a better way for this
                        // Do NOT suppress this. Eval should never go unnoticed.
                        eval(b.getAttribute('onclick') ?? '');
                    })
                );
            });

            element.appendChild(web);
            //#endregion
        } else if (feature.webPage == null && feature.dataColumns != null) {
            feature.dataColumns.forEach((key) => {
                if (typeof fdata[key] != 'undefined') {
                    const p = document.createElement('p');
                    p.innerText = key + ': ' + fdata[key];
                    element.appendChild(p);
                }
            });
        } else if (overlay === undefined) {
            return document.createElement('div');
        } else {
            for (let i = 0; i < 10; i++) {
                const p = document.createElement('p');
                p.innerText = 'A line of text';
                element.appendChild(p);
            }
        }

        const close = document.createElement('p');
        close.classList.add('close');
        close.innerText = '✖';

        close.addEventListener('click', (_) => map.removeOverlay(overlay));
        element.appendChild(close);
        return element;
    }

    /** Creates the overlay. Never elements are created with getOverlayElement, while legacy overlays are made in createGUIPopupElement */
    public static createOverlay(
        map: PluggableMap,
        layer: CTLayer,
        feature: ClickableFeature,
        coordinate: Coordinate
    ): boolean {
        if (!layer?.options?.overlayKey) return false;
        const overlay = new Overlay({
            position: coordinate,
            positioning: OverlayPositioning.BOTTOM_CENTER,
        });
        overlay.setElement(
            getOverlayElement(map, layer, feature, overlay) ?? this.createGUIPopupElement(map, feature, overlay)
        );
        map.addOverlay(overlay);
        return true;
    }

    public static getCenter(feature: FeatureLike): [number, number] {
        const ext = feature.getGeometry()!.getExtent();
        return [ext[0] + (ext[2] - ext[0]) / 2, ext[1] + (ext[3] - ext[1]) / 2];
    }

    private static isEnabled() {
        // Tool er select
        if (ControlHandler.ActiveToolType !== Tools.Select) return false;

        // Popup slået fra i URL
        //if (UrlParams.urlParams['popup'] != null && UrlParams.urlParams['popup'] != 'true')
        //return false;

        return true;
    }

    public static addPopupEventListener(map: PluggableMap) {
        this.map = map;
        if (!this._eventlistenerIsAdded) {
            map.on(
                'click',
                (e) =>
                    this.suppressClicks ||
                    map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
                        const features: Array<FeatureLike> = feature.get('features') ?? [feature];
                        // Hvis der ikke er clusters, og der allerede er åbnet et overlay, skal der ikke åbnes flere
                        if (
                            (layer as CTLayer)?.options?.clusterOptions?.distance === 0 &&
                            (e as IDictionary)['shouldStop'] === true
                        )
                            return;
                        // Overlay 1 - Enhedsoverlay når der trykkes på ikke-clustered prik
                        // Dækker alle features med en features property af længde 1.
                        if (this.isEnabled() && features != null && features.length === 1) {
                            const f = features[0];
                            if (ClickableFeature.checkType(f))
                                map.getView().fit(f.getGeometry()!.getExtent(), {
                                    padding: [200, 200, 200, 200],
                                    maxZoom: 13,
                                    duration: 1000,
                                }); // HER !!! ---------------------------------------------------
                            if (this.createOverlay(map, layer as CTLayer, f as ClickableFeature, this.getCenter(f)))
                                this.CenterFeatures(features);
                            // Bruges kun hvis den ikke skal cluster
                            (e as IDictionary)['shouldStop'] = true;
                        }
                        // Overlay 2 - Bygningsoverlay
                        else if (
                            this.isEnabled() &&
                            (ClickableFeature.checkType(feature) ||
                                (feature as ClickableFeature).featureType === ClickableFeatureType.SqlDel)
                        ) {
                            this.createOverlay(map, layer as CTLayer, feature as ClickableFeature, e.coordinate);
                        }
                        // Flere features -> Indikere et cluster, så der zoomes for at vise de enkelte features
                        else if (features != null && features.length > 1) {
                            const matchingData = SFC.matchingFeatures(feature);

                            // zoom to matches if any, otherwise zoom to all
                            this.zoomCluster(matchingData[0] ? matchingData[2] : features, true);
                        }
                    })
            );
        }
        // this._eventlistenerIsAdded = true;
    }

    /**
     * Zoom to show features in array
     */
    public static ZoomFeatures(features: FeatureLike[], options: IZoomOptions = {}) {
        options.animate ??= true;
        const { animate, ...rest } = options;
        this.zoomCluster(features, animate, rest);
    }

    /**
     * Zoom to show features in cluster. Also works for singular features.
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param features A list of the features to zoom in on.
     * @param animate If true, the zoom will be animated. Defaults to true
     *
     * WARNING: Setting animate true may stop zoom from working. Currently it seems to be if zoom is triggered outside OL events (fx in SFC)
     */
    private static zoomCluster(features: FeatureLike[], animate: boolean, options?: IZoomOptions) {
        const getPadding = () => {
            const hPad = document.body.clientHeight / 3;
            const wPad = document.body.clientWidth / 6;
            return [hPad, wPad, hPad, wPad];
        };

        const extent = this.getExtent(features);
        const padding = getPadding();

        this.map.getView().fit(extent, {
            padding: padding,
            maxZoom: options?.maxZoom ?? 17,
            duration: animate ? 667 : 0,
        });
    }

    public static CenterFeatures(features: FeatureLike[], animate = true) {
        const ext = this.getExtent(features);
        const center = [(ext[2] + ext[0]) / 2, (ext[3] + ext[1]) / 2];

        if (animate) this.map.getView().animate({ center, duration: 333 });
        else this.map.getView().setCenter(center);
    }

    private static getExtent = (features: FeatureLike[]) => {
        const xCoords: number[] = Array(features.length);
        const yCoords: number[] = Array(features.length);
        for (let i = 0; i < features.length; i++) {
            const coords = this.getCenter(features[i]);
            xCoords[i] = coords[0];
            yCoords[i] = coords[1];
        }
        return [_.min(xCoords)!, _.min(yCoords)!, _.max(xCoords)!, _.max(yCoords)!];
    };
}

//#endregion ClickableFeatures
