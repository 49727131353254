import { IBreadcrumbProps, IPathBreadcrumbProps } from './interfaces';
import { useLocation, useParams } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import Link from '@mui/material/Link';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { useAppSelector } from '../../../shared/hooks/redux-base-hooks';
import { useCTEffect } from '../../../shared/hooks/use-ct';
import { useEnhed } from '../../../shared/hooks/use-enhed';
import useEnhedList from '../../../shared/hooks/use-enhed-list';
import useFetch from '../../../shared/hooks/fetch-hooks/use-fetch';
import useHistoryNavigate from '../../../shared/hooks/extended-hooks/use-history-navigate';
import usePath from '../../../shared/hooks/use-path';

const Breadcrumb = ({ path, children }: IBreadcrumbProps) => {
    const { navigate } = useHistoryNavigate();

    const handleClick = () => {
        path && navigate(path);
    };

    return (
        <Link
            underline={path ? 'hover' : 'none'}
            sx={{
                display: 'block',
                alignItems: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                flex: 1,
                maxWidth: 'calc(50vw - 60px)',
            }}
            color='inherit'
            onClick={handleClick}
        >
            {children}
        </Link>
    );
};

const PathBreadcrumb = ({ path, subPath, active }: IPathBreadcrumbProps) => {
    const label = useAppSelector((s) => s.breadcrumb.labels[path.toLowerCase()]);
    return <Breadcrumb path={active ? path : undefined}>{label || subPath}</Breadcrumb>;
};

const EnhedBreadcrumb = () => {
    const { enhedList, addEnhed } = useEnhedList();
    const { id, enhed } = useEnhed();
    const [data, , , , , refetch] = useFetch<string>(`/drift/lookup/translate?kontext=enh&id=${id}`, {
        autoFetch: false,
    });
    const location = useLocation();
    useCTEffect(() => {
        if (id !== 0 && !enhed) {
            refetch();
        }
    }, [id]);
    useCTEffect(() => {
        if (data) {
            addEnhed({ id, value: data, searchcriteria: '' });
        }
    }, [data, enhedList]);
    let path = location.pathname + location.search + location.hash;
    path = path.replace(/^\/-?\d+/, `/-${id}`);
    return <Breadcrumb path={path}>{id === 0 ? 'Alle enheder' : enhed?.value ?? id}</Breadcrumb>;
};

const HomeBreadcrumb = () => {
    return (
        <Breadcrumb path='/'>
            <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />
            Hjem
        </Breadcrumb>
    );
};

const Breadcrumbs = () => {
    const { id } = useParams<{ id: string }>();
    const path = usePath();
    let iteratedPath = '';
    return (
        <MuiBreadcrumbs sx={{ color: '#474646', fontWeight: 600 }}>
            <HomeBreadcrumb />
            <EnhedBreadcrumb />
            {path.map((p, i, a) => {
                iteratedPath += `/${p}`;
                return (
                    <PathBreadcrumb
                        key={iteratedPath}
                        path={iteratedPath}
                        subPath={p}
                        active={id?.startsWith('-') || i !== a.length - 1} // Force active is picker is open
                    />
                );
            })}
        </MuiBreadcrumbs>
    );
};

export default Breadcrumbs;
