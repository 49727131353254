import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import { CSSProperties } from 'react';
import ContextPicker from './context/context-picker';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Outlet } from 'react-router-dom';
import OverviewMap from './overview.map';
import OverviewTree from './overview.tree';

const handleStyle: CSSProperties = {
    width: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
};

const OverviewLayout = () => {
    return (
        <PanelGroup direction='horizontal'>
            <Panel defaultSize={25}>
                <div className='h-full w-full flex flex-col'>
                    <div className='flex-none w-full'>
                        {/* <div className='h-16 bg-slate-600'> */}
                        <ContextPicker />
                        {/* </div> */}
                    </div>
                    <div className='flex-1 overflow-auto'>
                        <OverviewTree />
                    </div>
                </div>
            </Panel>
            <PanelResizeHandle style={handleStyle} className='flex justify-center items-center'>
                <MoreVertIcon color='primary' />
            </PanelResizeHandle>
            <Panel defaultSize={25}>
                <OverviewMap />
            </Panel>
            <PanelResizeHandle style={handleStyle} className='flex justify-center items-center'>
                <MoreVertIcon color='primary' />
            </PanelResizeHandle>
            <Panel>
                <Outlet />
            </Panel>
        </PanelGroup>
    );
};

export default OverviewLayout;
