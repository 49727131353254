import { ControlEventTarget, ExtendedEvent } from './ControlHandlerUtils';

import { Cluster } from 'ol/source';
import { ControlHandler } from './ControlHandler';
import { EnhLayer } from './deprecated/EnhLayer';
import { FeatureProperty } from './feature-utility';
import { GPSControl } from './GPSControl';
import { Layers } from './LayerMenuControl';
import { Map } from 'ol';
import { Notifyer } from './Notifyer';
import { cmSettings } from './CaretakerMapSettings';
import { easeIn } from 'ol/easing';

enum Cookies {
    Config = 'config',
}

interface configCookie {
    BaseKey: string | null;
    Zoom: number | null;
    Coords: number[] | null;
}

export class StaticCookieHandler {
    private static controlHandler: ControlHandler;
    private static map: Map;
    private static enhNotifyer: Notifyer;
    private static layers: Layers;
    private static _centerEnhed?: string;
    //#region get set
    public static get centerEnhed(): string | undefined {
        return this._centerEnhed;
    }
    public static set centerEnhed(enhedId: string | number | undefined) {
        this._centerEnhed = enhedId?.toString();
    }
    //#endregion

    public static init(controlHandler: ControlHandler, map: Map, enhNotifyer: Notifyer, layers: Layers) {
        this.controlHandler = controlHandler;
        this.map = map;
        this.enhNotifyer = enhNotifyer;
        this.layers = layers;
        this.setupFromConfig();
        this.addEventListeners();
    }

    private static setupFromConfig() {
        const cBody = (JSON.parse(this.read(Cookies.Config)) ?? {
            BaseKey: null,
            Zoom: null,
            Coords: null,
        }) as configCookie;

        //#region Akt on info

        // BaseLayer
        this.controlHandler.activeBaseILayerKey = Object.keys(this.layers.getBaseLayers()).includes(cBody.BaseKey!)
            ? cBody.BaseKey!
            : 'dtk_skaermkort_graa';

        // Zoom
        //this.map.getView().setZoom(cBody.Zoom ?? cmSettings.startZoomLevel);

        // Hvis cookie cords: Centrér på cookie coords
        if (cBody.Coords != null && cBody.Coords.length === 2)
            this.centerAtCoords(cBody.Coords, cBody.Zoom ?? cmSettings.startZoomLevel);
        // Ellers hvis der skal centrerers på bruger: Lad GPSControl håndtere det
        // Men set en eventlistener der så centrerer på enhed hvis gps ikke virker
        else if (GPSControl.centerOnUser) {
            // const callback = (e: ExtendedEvent) => {
            //     if (e.eventTarget === ControlEventTarget.GPSErrorState && e.value)
            //         this.centerAtEnh(this.centerEnhed);
            // }

            // // Lav eventlistener
            // this.controlHandler.addCustomEventListener('change', callback, { onlyOnce: true });

            // // Fjern eventlistener efter 5 sekunder, så en fejl om 10 minutter ikke pludseligt starter et zoom
            // // Promise for ikke at blokere tråden med timeout
            // new Promise<void>(resolve => setTimeout(() => {
            //     this.controlHandler.removeCustomEventListener('change', callback);
            //     resolve()
            // }, 5000));

            // Centrér på enhed hvis gps fejler inden for 5 sekunder

            this.controlHandler.doIfBool(
                this.controlHandler.GPSCenterError,
                ControlEventTarget.GPSCenterError,
                () => this.centerAtEnh(this.centerEnhed ?? '0'),
                {
                    timeout: 5000,
                }
            );
        }
        // Ellers: Centrér på given enhed
        else if (this.centerEnhed !== undefined) this.centerAtEnh(this.centerEnhed);
        cBody.Coords = null;
        cBody.Zoom = null;
        //#endregion

        // Save cookie
        this.write(Cookies.Config, JSON.stringify(cBody));
    }

    private static addEventListeners() {
        this.controlHandler.addCustomEventListener('change', (e: ExtendedEvent) => {
            switch (e.eventTarget) {
                case ControlEventTarget.ActiveBaseILayerKey:
                    this.write(
                        Cookies.Config,
                        JSON.stringify({ BaseKey: e.value, Zoom: null, Coords: null } as configCookie)
                    );
                    break;
            }
        });
    }

    public static saveConfigCookie() {
        const cBody = {
            BaseKey: this.controlHandler.activeBaseILayerKey,
            Zoom: this.map.getView().getZoom(),
            Coords: this.map.getView().getCenter(),
        } as configCookie;
        this.write(Cookies.Config, JSON.stringify(cBody));
    }

    //#region Coords

    public static centerAtCoords(coords: number[], zoom = 13, duration = 667) {
        this.map.getView().animate({ zoom: zoom, center: coords, duration: duration, easing: easeIn });
    }

    private static centerAtEnh(enhId: string) {
        if (!this.enhNotifyer.enhLoadDone) {
            // Lav eventlistener
            this.enhNotifyer.addCustomEventListener('change', () => {
                this.cae(enhId);
            });

            // Hvis enheden blev færdig med at loade imens eventet var ved at blive tilføjet, og intet event derfor fyrede:
            if (this.enhNotifyer.enhLoadDone) this.cae(enhId);
        } else this.cae(enhId);
    }

    //#region Error prone
    // MÅ KUN KALDES EFTER ET TJEK FOR OM ENHEDEN ER LOADET
    private static cae(enhId: string) {
        try {
            const urlLayers = this.layers.getUrlLayers();
            // Find ILayer med key der starter med 'ENH', cast til EnhLayer, cast source til det cluster features er tegnet på, find source og få alle feartures
            // Derfra findes featuren hvor EnhedSYS er lig id'et (som er et SYS) der blev sendt med fra portalen, og GeoJSON hentes fra featurens data-dictionary
            // GeoJSON parses til et object, da det er stringyfied JSON (f.eks. {"type": "Point","coordinates":[720841.267293782,6193691.48808686]}), og coordinaterne hentes
            const coords: number[] = JSON.parse(
                (
                    (
                        urlLayers[Object.keys(urlLayers).find((key) => key.startsWith('ENH'))!].layer as EnhLayer
                    ).getSource() as Cluster
                )
                    .getSource()
                    ?.getFeatures()
                    .find((f) => f.get(FeatureProperty.Data)['EnhedSYS'] === enhId)
                    ?.get(FeatureProperty.Data)['GeoJ']
            )['coordinates'];

            // Centrer kort på koordinater
            this.centerAtCoords(coords, 13);
        } catch (error) {
            console.error(
                'Error. Vi kunne ikke finde enhedens koordinater. Hvis du mener dette er en fejl bør du kontakte support, så vi kan fikse det'
            );
            //// Skal der laves en mere diskret besked der dukker op i hjørnet af kortet?
            //alert('Vi kunne ikke finde enhedens koordinater. Hvis du mener dette er en fejl bør du kontakte support, så vi kan fikse det');
        }
    }
    //#endregion

    //#endregion
    //#region Cookie lib

    private static read(name: string) {
        const result = new RegExp('(?:^|; )' + encodeURIComponent(name) + '=([^;]*)').exec(document.cookie);
        return result ? JSON.parse(result[1]) : null;
    }

    private static write(name: string, value: unknown, days?: number) {
        if (!days) {
            days = 365 * 20;
        }
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = '; expires=' + date.toUTCString();
        const sameSite = '; SameSite=Strict';
        document.cookie = name + '=' + JSON.stringify(value) + expires + sameSite + '; path=/';
    }

    private static remove(name: string) {
        this.write(name, '', -1);
    }

    //#endregion
}

export class CookieHandler {
    private controlHandler: ControlHandler;
    private map: Map;
    private enhNotifyer: Notifyer;
    private layers: Layers;

    constructor(controlHandler: ControlHandler, map: Map, enhNotifyer: Notifyer, layers: Layers) {
        this.controlHandler = controlHandler;
        this.map = map;
        this.enhNotifyer = enhNotifyer;
        this.layers = layers;
        this.setupFromConfig();
        this.addEventListeners();
        //this.saveFunctionForControlHandler.bind(this);
        this.controlHandler.saveCookie = this.saveFunctionForControlHandler;
        this.controlHandler.saveCookie.bind(this);
        //this.controlHandler.activeBaseILayerKey = 'off';
    }

    private setupFromConfig() {
        const cBody = (JSON.parse(this.read(Cookies.Config)) ?? {
            BaseKey: null,
            Zoom: null,
            Coords: null,
        }) as configCookie;

        //#region Akt on info

        // BaseLayer
        this.controlHandler.activeBaseILayerKey = Object.keys(this.layers.getBaseLayers()).includes(cBody.BaseKey!)
            ? cBody.BaseKey!
            : 'dtk_skaermkort_graa';

        // Zoom
        this.map.getView().setZoom(cBody.Zoom ?? cmSettings.startZoomLevel);
        cBody.Zoom = null;

        // Coords
        if (cBody.Coords != null && cBody.Coords.length === 2) this.centerAtCoords(cBody.Coords);
        else if (StaticCookieHandler.centerEnhed !== undefined) this.centerAtEnh(StaticCookieHandler.centerEnhed);
        cBody.Zoom = null;
        //#endregion

        // Save cookie
        this.write(Cookies.Config, JSON.stringify(cBody));
    }

    private addEventListeners() {
        // this.controlHandler.addCustomEventListener('change', (e : ExtendedEvent) => {
        //     switch (e.eventTarget) {
        //         case ControlEventTarget.SaveStatus:
        //             alert(`SaveStatus from CookieHandler: ${e.value}`);
        //             switch (e.value) {
        //                 case SaveStatus.Saving:
        //                     let cBody = <configCookie>{
        //                         BaseKey : this.controlHandler.activeBaseILayerKey,
        //                         Zoom : this.map.getView().getZoom(),
        //                         Coords : null
        //                     }
        //                     this.write(Cookies.Config, JSON.stringify(cBody));
        //                     alert(`Cookie \'${Cookies.Config}\'has been written. Body is as follows:\n\n${JSON.stringify(cBody)}`);
        //                     break;
        //             }
        //             break;
        //     }
        // });
    }

    private saveFunctionForControlHandler() {
        alert('calling functions like this actually works');
        const cBody = {
            BaseKey: this.controlHandler.activeBaseILayerKey,
            Zoom: this.map.getView().getZoom(),
            Coords: null,
        } as configCookie;
        this.write(Cookies.Config, JSON.stringify(cBody));
    }

    //#region Coords

    private centerAtCoords(coords: number[]) {
        this.map.getView().setCenter(coords);
    }

    private centerAtEnh(enhId: string) {
        if (!this.enhNotifyer.enhLoadDone) {
            // Lav eventlistener
            this.enhNotifyer.addCustomEventListener('change', () => {
                this.cae(enhId);
            });

            // Hvis enheden blev færdig med at loade imens eventet var ved at blive tilføjet, og intet event derfor fyrede:
            if (this.enhNotifyer.enhLoadDone) this.cae(enhId);
        } else this.cae(enhId);
    }

    //#region Error prone
    // MÅ KUN KALDES EFTER ET TJEK FOR OM ENHEDEN ER LOADET
    private cae(enhId: string) {
        try {
            const urlLayers = this.layers.getUrlLayers();
            // Find ILayer med key der starter med 'ENH', cast til EnhLayer, cast source til det cluster features er tegnet på, find source og få alle feartures
            // Derfra findes featuren hvor EnhedSYS er lig id'et (som er et SYS) der blev sendt med fra portalen, og GeoJSON hentes fra featurens data-dictionary
            // GeoJSON parses til et object, da det er stringyfied JSON (f.eks. {"type": "Point","coordinates":[720841.267293782,6193691.48808686]}), og coordinaterne hentes
            const coords: number[] = JSON.parse(
                (
                    (
                        urlLayers[Object.keys(urlLayers).find((key) => key.startsWith('ENH'))!].layer as EnhLayer
                    ).getSource() as Cluster
                )
                    .getSource()
                    ?.getFeatures()
                    .find((f) => f.get(FeatureProperty.Data)['EnhedSYS'] === enhId)
                    ?.get(FeatureProperty.Data)['GeoJ']
            )['coordinates'];

            // Centrer kort på koordinater
            this.centerAtCoords(coords);
        } catch (error) {
            console.error(
                'Vi kunne ikke finde enhedens koordinater. Hvis du mener dette er en fejl bør du kontakte support, så vi kan fikse det'
            );
            //// Skal der laves en mere diskret besked der dukker op i hjørnet af kortet?
            //alert('Vi kunne ikke finde enhedens koordinater. Hvis du mener dette er en fejl bør du kontakte support, så vi kan fikse det');
        }
    }
    //#endregion

    //#endregion
    //#region Cookie lib

    read(name: string) {
        const result = new RegExp('(?:^|; )' + encodeURIComponent(name) + '=([^;]*)').exec(document.cookie);
        return result ? JSON.parse(result[1]) : null;
    }

    write(name: string, value: unknown, days?: number) {
        if (!days) {
            days = 365 * 20;
        }
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = '; expires=' + date.toUTCString();
        const sameSite = '; SameSite=Strict';
        document.cookie = name + '=' + JSON.stringify(value) + expires + sameSite + '; path=/';
    }

    remove(name: string) {
        this.write(name, '', -1);
    }

    //#endregion
}
